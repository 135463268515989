import React from 'react';

import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { ProductDetailView } from 'components';
import { useProduct } from 'boxenStopHooks';

// Constant
import { itemTypes } from 'constants/itemTypes';

const ProductItemDetail = ({ params }) => {
  const { data: productData } = useProduct(params.productId);

  return (
    <>
      <ProductDetailView
        productData={productData}
        itemType={itemTypes.PRODUCT}
      />
    </>
  );
};

export default injectIntl(ProductItemDetail);
